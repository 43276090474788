import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { theme, responsiveFont, fontRegular, FormButton } from '../util/style'
import HHIcon from '../images/happyhaus-H-form-links.png'
import SEO from '../components/seo'

const Bio = () => {
  return (
    <>
      <SEO title="Bio" />
      <Icon>
        <img src={HHIcon} alt="" />
        <Link to="/" />
      </Icon>
      <Social>
        <a href="https://www.instagram.com/happy_haus/">@happy_haus</a>
      </Social>
      <Button>
        <a href="https://happyhaus.com.au/gallery?utm_source=instagram&utm_medium=bio&utm_campaign=hh-social">
          Haus Gallery
        </a>
      </Button>
      <Button>
        <a href="https://happyhaus.com.au/articles?utm_source=instagram&utm_medium=bio&utm_campaign=hh-social">
          Articles & Insights
        </a>
      </Button>
      <Button>
        <a href="https://happyhaus.com.au/resources?utm_source=instagram&utm_medium=bio&utm_campaign=hh-social">
          Haus Resources
        </a>
      </Button>
      <Button>
        <a href="https://happyhaus.com.au/philosophy?utm_source=instagram&utm_medium=bio&utm_campaign=hh-social">
          Why Happy Haus
        </a>
      </Button>
      <Button>
        <a href="https://happyhaus.com.au/contact?utm_source=instagram&utm_medium=bio&utm_campaign=hh-social">
          Contact our team
        </a>
      </Button>
      <Button>
        <a href="https://happyhaus.com.au/work-with-us?utm_source=instagram&utm_medium=bio&utm_campaign=hh-social">
          Join our team
        </a>
      </Button>
    </>
  )
}

export default Bio

const Icon = styled.div`
  width: 140px;
  height: 140px;
  margin-bottom: 1.4em;
  position: relative;
  a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &:after {
    content: ' ';
    display: block;
    border-radius: 140px;
    border: 1px solid #ddd;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 140px;
    height: 140px;
    z-index: 1;
  }
  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 140px;
  }
`

const Social = styled.div`
  text-align: center;
  margin-bottom: 2.4em;
  color: ${theme.colors.grey1};
  font-size: 20px;
  a {
    color: ${theme.colors.grey1};
  }
`

const Button = styled.div`
  width: 100%;
  margin-bottom: 1em;
  font-size: 19px;
  @media (max-width: 400px) {
    font-size: 17px;
  }
  a {
    display: block;
    text-align: center;
    padding: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    margin: ${(props) => (props.align === 'left' ? '0 10px 0 0' : '0 10px')};
    color: ${theme.colors.white};
    background: ${(props) => (props.bg ? props.bg : theme.colors.grey1)};
    @media (max-width: 800px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`
